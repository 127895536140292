import React, { useEffect, useState } from "react";

const TermsAndConditions = () => {
	return (
		<div style={{ paddingTop: "20px" }}>
			<h4>
				<strong>Terms and Conditions – VSU Incident Report Application</strong>
			</h4>
			<p>
				<strong>1. Introduction</strong>
				<br />
				The Volatile Substance Use (VSU) Incident Reporting and Response system was developed to assist local service providers to collect
				information about VSU incidents and ensure a timely and coordinated response. This VSU Incident Report Application (App) is a
				mechanism developed by the Mental Health Commission (Commission) to facilitate the transmission of information from service providers
				across all regions of Western Australia (with the exception of the Perth metro area) to their respective Central Coordinating Agency
				(CCA).
			</p>
			<p>
				By using this App, you agree to comply with and be bound by these terms and conditions. Please review these terms carefully. If you do
				not agree with them, do not use this App.
			</p>
			<p>
				<strong>2. Purpose of the App</strong>
				<br />
				The purpose of the VSU Incident Report is to allow users to submit reports related to VSU incidents. Submitted VSU Incident Reports
				are sent to the relevant CCA based on the location information provided by the reporter.&nbsp; The CCA will then coordinate a response
				in line with the Volatile Substance Use Incident Reporting and Response protocols (see{" "}
				<a href="https://vsu.mhc.wa.gov.au/vsu-in-wa/incident-reporting-and-response/">
					<span>https://vsu.mhc.wa.gov.au/vsu-in-wa/incident-reporting-and-response/</span>
				</a>
				). By submitting a VSU Incident Report, you understand that the information will be used for the purpose of coordinating a response to
				the reported VSU incident.
			</p>
			<p>
				<strong>Please note:</strong> Submitting a VSU Incident Report will <strong>not</strong> result in immediate Police assistance. If you
				require Police attendance, dial <strong>131 444</strong>, or if it is a life-threatening emergency, dial{" "}
				<strong>triple zero (000)</strong>. VSU Incident Reports are not monitored 24/7 and are received and actioned during office hours only
				by your local CCA. Please allow up to <strong>72 hours for a response.</strong>
			</p>
			<p>
				<strong>3. Image Upload Policy</strong>
				<br />
				This App allows reporters to upload images related to the VSU Incident Report. By uploading an image, you agree to the following:
			</p>
			<ul>
				<li>
					<span>Do </span>
					<strong>
						<span>not</span>
					</strong>
					<span> upload sensitive, inappropriate, or unlawful images.</span>
				</li>
				<li>
					<span>Do </span>
					<strong>
						<span>not</span>
					</strong>
					<span> upload images containing identifiable people.</span>
				</li>
				<li>
					<span>
						The Commission reserves the right to remove or delete any images that violate these terms or that may be deemed inappropriate.
					</span>
				</li>
				<li>
					<span>You are solely responsible for ensuring that any uploaded content complies with these conditions.</span>
				</li>
			</ul>
			<p>
				<strong>4. Confidentiality and Use of Information</strong>
				<br />
				The information you submit via the VSU Incident Report is treated with strict confidentiality. It will only be shared by the CCA with
				relevant stakeholders, including government agencies as required, who are involved in addressing the VSU incident.
			</p>
			<p>
				By using this App, you agree to have the information provided to your local CCA in accordance with the protocols of the Volatile
				Substance Use Incident Reporting and Response system (see{" "}
				<a href="https://vsu.mhc.wa.gov.au/vsu-in-wa/incident-reporting-and-response/">
					<span class="Hyperlink">https://vsu.mhc.wa.gov.au/vsu-in-wa/incident-reporting-and-response/</span>
				</a>
				).
			</p>
			<p>
				The CCA receiving the information from the VSU Incident Report will use the data solely for the purpose of managing and responding to
				the reported incident.
			</p>
			<p>
				Your personal details, will not be disclosed to unauthorised individuals, and all efforts will be made to ensure the security and
				privacy of your information.
			</p>
			<p>
				Checking the box at the beginning of the “Step 2” page of this App means that your personal details and your identity as the reporter
				will be kept confidential and will not be shared by the CCA when coordinating a response. However, you may be contacted by the CCA for
				verification purposes.
			</p>
			<p>
				The Commission does not have access to the data collected within the VSU Incident Reports and provides only the mechanism for the
				collection of data by the CCAs for the purpose of VSU Incident response. The Commission only has the ability to access information
				associated with site usage and feedback.
			</p>
			<p>
				<strong>Security and Storage of Information</strong>
			</p>
			<p>
				While the Commission provides a secure environment, you should be aware there are inherent risks associated with the transmission of
				information via this online form.
			</p>
			<p>
				The Commission has established responsible and accountable collection and management arrangements to protect personal information
				within the Commission against loss, misuse or inappropriate modification or disclosure.
			</p>
			<p>
				The Commission places great importance on the security of all information associated with our customers, clients and contractors. The
				Commission have security measures in place to attempt to protect against the loss, misuse and alteration of personal information under
				our control.
			</p>
			<p>Personal information is de-identified or destroyed securely when no longer required by the Commission.</p>
			<p>
				Information stored within our computer systems can only be accessed by those entrusted with authority and computer network password
				sanctions.
			</p>
			<p>
				No data transmission over the Internet can be guaranteed to be 100 per cent secure. As a result, while the Commission strives to
				protect user's personal information, the Commission cannot ensure or warrant the security of any information transmitted to it or from
				its online products or services, and users do so at their own risk. Once the Commission receives your transmission, it makes every
				effort to ensure its security on its systems.
			</p>
			<p>
				Ultimately, you are solely responsible for keeping your passwords and/or account information secret. You should be careful and
				responsible whenever you are online.
			</p>
			<p>
				<strong>Site Visit Data</strong>
			</p>
			<p>
				This website is operated by the Commission. When visiting this site, a record of your visit may be partly&nbsp;logged through our
				Google Tag Manager Analytics process. Information is tracked&nbsp;for statistical purposes and is used by the Commission to monitor
				the use of the site, and to make improvements.
			</p>
			<p>The information which may be tracked&nbsp;when you access our website is:</p>
			<ul>
				<li>
					<span>the date and time of the visit to the site;</span>
				</li>
				<li>
					<span>the pages accessed and the documents downloaded;</span>
				</li>
				<li>
					<span>the user’s operating system; and</span>
				</li>
				<li>
					<span>the user’s web browser version.</span>
				</li>
			</ul>
			<p>
				No attempt is made to identify users&nbsp;or their browsing activities. When entering this site all recording of IP scripting&nbsp;is
				denied in order to protect the identity of the information provider.
			</p>
			<p>
				<strong>5. Mandatory Reporting Requirements</strong>
				<br />
				In some instances, based on the information provided, the CCA will be legally obligated to report certain details (e.g., involving the
				safety and well-being of a child) to relevant authorities such as the Department of Communities or WA Police. Such reporting will be
				done in accordance with the <strong>Children and Community Services Act 2004</strong> and other relevant legislation.
			</p>
			<p>
				<strong>6. Reporter Protections</strong>
			</p>
			<p>
				If you submit a VSU Incident Report through this App, your identity and personal information will be protected to the fullest extent
				possible, consistent with applicable laws and regulations.
			</p>
			<p>
				Under the provisions of the <strong>Children and Community Services Act 2004</strong> and related statutes, reporters are protected
				from liability if they submit information in good faith. This means that as long as the report is made with honest intentions and not
				for malicious purposes, the reporter is protected from legal consequences, even if the report turns out to be inaccurate.
			</p>
			<p>
				Information provided in good faith cannot be used against the reporter, and they are protected from retribution or adverse actions
				resulting from the submission of the report.
			</p>
			<p>
				The relevant CCA is required to handle the information provided with strict confidentiality and to protect the identity of the
				reporter whenever possible.
			</p>
			<p>
				<strong>7. Responsibilities of the Commission </strong>
			</p>
			<p>
				The Commission, along with the CCAs, is responsible for ensuring that the information provided in VSU Incident Reports is used solely
				for the purpose of coordinating responses to VSU incidents.
			</p>
			<p>
				Each agency involved is required to comply with the laws and regulations governing the handling of personal and incident data, as
				outlined in the <strong>Children and Community Services Act 2004</strong> and other applicable legislation.
			</p>
			<p>
				CCAs receiving information in VSU Incident Reports are bound by confidentiality agreements and are required to protect the identity of
				the reporter wherever possible.
			</p>
			<p>
				<strong>8. Limitations of Response</strong>
			</p>
			<p>While the App facilitates the reporting of VSU incidents, it does not guarantee immediate action or intervention.</p>
			<p>
				As the App is not monitored 24/7, there may be delays in responses as VSU Incident Reports are handled only during business hours by
				the CCA.
			</p>
			<p>
				In the case of an emergency or immediate threat, reporters should contact emergency services directly by dialling{" "}
				<strong>triple zero (000)</strong>.
			</p>
			<p>
				Reporters may choose not to include identifying details of person/s involved in an incident, however, doing so may limit the ability
				of the CCA to coordinate a response to the full extent of their abilities.
			</p>
			<p>
				<strong>9. User Responsibilities</strong>
			</p>
			<p>As a reporter you agree to provide accurate, complete, and truthful information when submitting a VSU Incident Report.</p>
			<p>VSU Incident Reports must only be submitted for legitimate VSU incidents.</p>
			<p>You must not submit VSU Incident Reports with malicious intent or for purposes other than what the App is designed for.</p>
			<p>You agree not to misuse the App by submitting inappropriate, false, or misleading information.</p>
			<p>
				<strong>10. Incident Reporting</strong>
			</p>
			<p>
				VSU Incident Reports submitted through the App will be sent to the appropriate CCA based on the location of the incident specified in
				the VSU Incident Report and where applicable, the CCA of the home region of the person/s involved (if different from where incident
				occurred).
			</p>
			<p>
				All information provided in the VSU Incident Report is handled according to the relevant CCA’s Privacy Policy and the VSU Incident
				Reporting and Response system protocols and may be shared with relevant stakeholders, including government agencies as required, for
				the purpose of appropriately responding to the VSU incident.
			</p>
			<p>
				<strong>Please note:</strong> VSU Incident Reports are handled during business hours by the relevant CCA, and responses may take up to
				72 hours (for example, if received on a Friday they may not be responded to until&nbsp; the next business day being the Monday).
			</p>
			<p>
				<strong>11. Service Availability</strong>
				<br />
				While the Commission aims to ensure that the App is available at all times, the Commission cannot guarantee uninterrupted access due
				to maintenance, updates, or unforeseen technical issues. The Commission, or the CCAs, are not liable for any delays or interruptions
				in service or for the consequences of any such unavailability.
			</p>
			<p>
				<strong>12. Accuracy of Information</strong>
				<br />
				You acknowledge that the responsibility for the accuracy of the information provided lies with the person submitting the VSU Incident
				Report. The Commission does not verify the accuracy of the data provided in each VSU Incident Report and cannot be held liable for any
				false or misleading information submitted by reporters.
			</p>
			<p>
				<strong>13. Privacy and Data Protection</strong>
				<br />
				Your privacy is important to the Commission. Any personal information you provide when submitting a VSU Incident Report will be
				handled in accordance with our Privacy Policy, which outlines how the Commission collect, use, and store your data. The Commission
				takes reasonable steps to protect the information you submit, but cannot guarantee the security of data transmitted over the internet.
			</p>
			<p>
				The Commission is committed to complying with Commonwealth legislation governing privacy of personal information by businesses and to
				protecting and safeguarding your privacy when you deal with the Commission.
			</p>
			<p>
				Personal identifiable information is defined as information or an opinion (including information or an opinion forming part of a
				database), whether true or not, and whether recorded in a material form or not, about an individual whose identity is apparent, or can
				reasonably be ascertained, from the information or opinion.
			</p>
			<p>The Commission will only collect personal information if it is necessary for one of our functions or activities.</p>
			<p>
				<strong>14. Limitation of Liability</strong>
				<br />
				To the fullest extent permitted by law, the Commission are not liable for any direct, indirect, incidental, or consequential damages
				that may arise from (but is not limited to):
			</p>
			<ul>
				<li>
					<span>The submission of VSU Incident Reports.</span>
				</li>
				<li>
					<span>The use or inability to use the App.</span>
				</li>
				<li>
					<span>Errors or omissions in the information provided in a VSU Incident Report.</span>
				</li>
			</ul>
			<p>
				<strong>15. Modifications to Terms</strong>
				<br />
				The Commission reserves the right to modify or update these terms at any time. Any changes will be posted on the App, and it is your
				responsibility to review the terms regularly. Continued use of the App after changes have been made signifies your acceptance of the
				updated terms.
			</p>
			<p>
				<strong>16. Governing Law</strong>
				<br />
				These terms and conditions are governed by the laws of Western Australia. Any disputes arising from the use of this App shall be
				subject to the jurisdiction of the courts in Western Australia.
			</p>
			<p>
				<strong>17. Contact Information</strong>
				<br />
				If you have any questions or concerns regarding these terms or the use of the A VSU, please contact the Commission at{" "}
				<a href="https://vsu.mhc.wa.gov.au/contactfeedback/">
					<span className="Hyperlink">https://vsu.mhc.wa.gov.au/contactfeedback/</span>
				</a>
				.
			</p>
			<p>&nbsp;</p>
		</div>
	);
};

export default TermsAndConditions;
