import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
//import App from './App';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/home";
import VSUIncident from "./screens/vsuincident";
import VSUIncidentBreif from "./screens/vsuincident-breif";
//import { handleConnection } from './config/checkonlinestatus';
import PWAPrompt from "react-ios-pwa-prompt2";
import PreviewPage from "./screens/preview";
import PWAInstaller from "./controller/pwainstaller";
import TermsAndConditions from "./screens/terms-and-conditions";
const root = ReactDOM.createRoot(document.getElementById("root"));
const handleKeyEnter = (e) => {
	if (e?.key === "Enter") {
		const ignoredField = ["textarea", "button"];
		if (ignoredField.includes(e?.target?.localName)) {
		} else {
			e.preventDefault();
		}
	}
};
document.addEventListener("keydown", handleKeyEnter);
root.render(
	<React.StrictMode>
		{/* <ReactPWAInstallProvider> */}
		<BrowserRouter>
			<Routes>
				<Route index element={<Home />} />
				<Route path="vsu-incident-report" element={<VSUIncident />} />
				<Route path="vsu-incident-report/:id" element={<VSUIncident />} />
				<Route path="vsu-incident-report-breif/" element={<VSUIncidentBreif />} />
				<Route path="vsu-incident-report-breif/:id" element={<VSUIncidentBreif />} />
				<Route path="vsu-incident-report-preview" element={<PreviewPage />} />
				<Route path="terms-and-conditions" element={<TermsAndConditions />} />
			</Routes>
		</BrowserRouter>
		<PWAInstaller />
		<PWAPrompt />
	</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
